import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentListIcon,
  EyeSlashIcon,
  HandThumbUpIcon,
  LightBulbIcon,
  SwatchIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { CSSProperties } from "react";
import { SmallFeature } from "./LandFeatures/components/SmallFeature";

type ValueDef = {
  name: string;
  description: string;
  icon?: string;
};

type AboutUsValuesProps = {
  blok: {
    title: string;
    description: string;
    values?: ValueDef[];
  };
  className?: string;
  style?: CSSProperties;
};

export const AboutUsValues = ({
  blok: { title, description, values },
  className,
  style,
}: AboutUsValuesProps) => {
  const resolveIcon = {
    lightbulb: LightBulbIcon,
    eyeoff: EyeSlashIcon,
    thumbup: HandThumbUpIcon,
    swatch: SwatchIcon,
    chat: ChatBubbleLeftRightIcon,
    clipboard: ClipboardDocumentListIcon,
  };
  return (
    <div
      className={clsx("bg-gray-100 py-24 sm:py-32", className)}
      style={style}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            {!!title && (
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h2>
            )}
            {!!description && (
              <p className="mt-6 text-base leading-7 text-gray-600">
                {description}
              </p>
            )}
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
            {values.map((feature) => (
              <SmallFeature
                key={feature.name}
                icon={resolveIcon[feature.icon]}
                name={feature.name}
                description={feature.description}
                bgColor="bg-gray-100"
              />
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

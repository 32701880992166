import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import MarkdownReact from "marked-react";
import { getTranslatedSlug } from "utils/router.helper";
import { v4 as uuidv4 } from "uuid";

type MarkdownProps = {
  openLinksInNewTab?: boolean;
  children?: string;
};

export const Markdown = ({ openLinksInNewTab, children }: MarkdownProps) => {
  return (
    <MarkdownReact
      renderer={{
        table: (children) => (
          <div key={`table-${uuidv4()}`} className="overflow-x-auto">
            <table>{children}</table>
          </div>
        ),
        link: (href, text) => (
          <CustomLink
            key={`link-${uuidv4()}`}
            href={getTranslatedSlug(href)}
            className="underline md:hover:text-black"
            target={openLinksInNewTab ? "_blank" : "_self"}
          >
            {text}
          </CustomLink>
        ),
      }}
    >
      {children}
    </MarkdownReact>
  );
};

import clsx from "clsx";

export const SmallFeature = ({
  name,
  description,
  icon: IconComp,
  bgColor = "bg-gray-50",
}) => (
  <div className="pt-6">
    <div className={clsx("flow-root rounded-lg bg-gray-50 px-6 pb-8", bgColor)}>
      <div className="-mt-6">
        <div>
          <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-primary-500 to-primary-600 p-3 shadow-lg">
            <IconComp className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
        </div>
        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
          {name}
        </h3>
        <p className="mt-5 text-base text-gray-500">{description}</p>
      </div>
    </div>
  </div>
);

import { BlogCard } from "@crafthunt-ui/BlogCard";
import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { getFullSlug } from "utils/router.helper";

type BlogCardsSectionProps = {
  locale: string;
  blok: {
    title?: string;
    description?: string;
    content?: string;
    blogPosts: any[];
    isAboveTheFold: boolean;
  };
  className?: string;
  style?: React.CSSProperties;
};

export const BlogCardsSection = ({
  locale,
  blok: { title, description, content, blogPosts, isAboveTheFold },
  className = undefined,
  style = undefined,
}: BlogCardsSectionProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx("relative bg-gray-50 py-16 sm:py-24 lg:py-32", className)}
      style={style}
    >
      <div className="relative">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <SectionHeader
            label={title}
            description={description || t("recommendations-text")}
            content={content}
          />
        </div>
        <div className="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
          {blogPosts.map((post, index) => {
            const blogStory = post.postLink?.story;
            const postLink = getFullSlug(
              blogStory?.full_slug || post.postLink?.url
            );
            return (
              <BlogCard
                key={post._uid || blogStory?.content?._uid}
                locale={locale}
                postImage={
                  blogStory?.content?.coverimage?.filename ||
                  post.postImage?.filename
                }
                postImagePriority={isAboveTheFold && index < 6}
                postTitle={blogStory?.content?.name || post.postTitle}
                postPreview={blogStory?.content?.intro || post.postPreview}
                postDate={blogStory?.created_at || post.postDate}
                postLength={blogStory?.content?.readingTime || post.postLength}
                postLink={postLink}
                category={blogStory?.content?.category || post.category}
                author={blogStory?.content?.author || post.author}
                hideFooter={post.hideFooter || false}
                authorImage={
                  blogStory?.content?.authorImage || post.authorImage
                }
                showButton={blogStory?.content?.showButton || post.showButton}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

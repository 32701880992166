import { Carousel, CarouselSlide } from "@crafthunt-ui/Carousel/Carousel";
import clsx from "clsx";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";

const LogoCloudSlider = ({
  blok,
  className = undefined,
  style = undefined,
}) => {
  return (
    <div
      className={clsx("overflow-hidden bg-gray-100", className)}
      style={style}
    >
      <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <p className="mb-8 text-center text-base font-semibold text-gray-500 sm:prose-xl lg:prose-lg xl:prose-xl">
          {blok.label}
        </p>

        <Carousel
          spaceBetween={100}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          loop
          centeredSlides
        >
          {blok.logos?.map((item) => (
            <CarouselSlide key={item.id || item._uid}>
              <div className="relative col-span-1 mt-6 flex h-16 justify-center md:col-span-2 lg:col-span-1">
                <NextImage
                  className="h-full object-contain"
                  src={transformImage(
                    item.filename,
                    "0x100/filters:grayscale():quality(80):fill(transparent)"
                  )}
                  alt={item.alt || "Company logo"}
                  fill
                  sizes="(max-width: 767px) 80vw, (max-width: 1200px) 33vw, 20vw"
                />
              </div>
            </CarouselSlide>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default LogoCloudSlider;

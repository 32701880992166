import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";
import { getTranslatedSlug } from "utils/router.helper";

const CTAFeatureScreenshot = ({
  blok,
  className = undefined,
  style = undefined,
}) => {
  return (
    <div className={clsx("bg-white py-16 sm:py-12", className)} style={style}>
      <div className="mx-auto max-w-7xl">
        <div
          {...storyblokEditable(blok)}
          className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-2xl sm:px-6 sm:py-24 md:px-8 lg:px-16"
        >
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-primary-500">
                    {blok.preheader}
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    {blok.header}
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                    <div className="flow-root">
                      <ul role="list" className="-mb-8">
                        {blok.steps.map((event, eventIdx) => (
                          <li key={event._uid}>
                            <div className="relative pb-8">
                              {eventIdx !== blok.steps.length - 1 ? (
                                <span
                                  className="absolute left-5 top-4 -ml-px h-full w-0.5 bg-gray-400"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="relative flex space-x-3">
                                <div>
                                  <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-3xl bg-primary-600 text-white">
                                      {eventIdx + 1}
                                    </div>
                                  </dt>
                                </div>
                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                  <div>
                                    <p className="text-xl tracking-tight text-white sm:text-2xl">
                                      <span className="font-bold tracking-wide">
                                        {event.header}
                                      </span>
                                      <span className="mt-2 block text-lg tracking-wide text-gray-300">
                                        {event.description}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                        {blok.cta?.length &&
                          blok.cta.map((ctaBlok) => (
                            <div key={ctaBlok._uid} className="ml-12">
                              <AuthCTAButton blok={ctaBlok} />
                            </div>
                          ))}
                        {!!blok.buttonLabel && (
                          <CustomLink
                            isCTA
                            id={`cta-feature-screenshot-${blok._uid}`}
                            href={getTranslatedSlug(blok.buttonLink, {
                              fromJourney: blok.buttonJourney,
                            })}
                            target={blok.buttonLink?.target}
                            className="text-md ml-12 rounded-sm bg-primary-600 px-3.5 py-4 font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                          >
                            {blok.buttonLabel}
                          </CustomLink>
                        )}
                      </ul>
                    </div>
                  </dl>
                </div>
              </div>
              {!!blok.image?.filename && (
                <div className="relative ml-10 w-[57rem] rounded-xl shadow-xl">
                  <NextImage
                    className="hidden h-full w-full rounded-xl object-cover shadow-xl lg:block"
                    src={transformImage(
                      blok.image.filename,
                      "0x1000/filters:quality(80)"
                    )}
                    alt="Product screenshot"
                    fill
                    sizes="(max-width: 1500px) 50vw, 30vw"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTAFeatureScreenshot;

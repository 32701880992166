import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import AppDownloadLink from "@crafthunt-ui/AppDownloadLink/AppDownloadLink";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import EmailSubmitField from "@crafthunt-ui/EmailSubmitField";
import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";
import { getTranslatedSlug } from "utils/router.helper";

const LandHero = ({
  blok,
  settings,
  className = undefined,
  style = undefined,
}) => {
  const checkmarkList = [
    blok.first_checkmark_label,
    blok.second_checkmark_label,
  ].filter(Boolean);

  return (
    <div
      className={clsx(
        "bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pb-14 lg:pt-8",
        className
      )}
      style={style}
    >
      <div className="mx-auto max-w-7xl px-10 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:py-8 lg:pr-4">
            <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
              <span className="block">{blok.title}</span>
              <span className="block bg-gradient-to-r from-primary-200 to-primary-400 bg-clip-text pb-3 text-transparent sm:pb-5">
                {blok.colorTitle}
              </span>
            </h1>
            <div className="prose-primary prose text-gray-300 sm:prose-xl lg:prose-lg xl:prose-xl">
              <Markdown>{blok.description}</Markdown>
            </div>
            {
              {
                ["appDownload"]: (
                  <div className="mt-6">
                    <AppDownloadLink />
                  </div>
                ),
                ["ctaButtons"]: (
                  <div className="mt-10 flex flex-wrap items-center gap-6 lg:mt-12 lg:shrink-0">
                    {!!blok.primaryBtnText && (
                      <CustomLink
                        isCTA
                        id={`cta-land-hero-primary-${blok._uid}`}
                        href={getTranslatedSlug(
                          blok.primaryBtnLink,
                          { fromJourney: blok.primaryBtnJourney },
                          "https://admin.crafthunt.app/signup"
                        )}
                        target={blok.primaryBtnLink?.target}
                        className="w-full rounded-sm bg-primary-600 px-5 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:w-auto"
                      >
                        {blok.primaryBtnText}
                      </CustomLink>
                    )}
                    {!!blok.secondaryBtnText && (
                      <CustomLink
                        isCTA
                        id={`cta-land-hero-secondary-${blok._uid}`}
                        href={getTranslatedSlug(
                          blok.secondaryBtnLink,
                          { fromJourney: blok.secondaryBtnJourney },
                          "https://link.crafthunt.app/termin"
                        )}
                        target={blok.secondaryBtnLink?.target}
                        className="w-full py-3 text-center text-sm font-semibold leading-6 text-white hover:text-primary-500 sm:w-auto"
                      >
                        {blok.secondaryBtnText}{" "}
                        <span aria-hidden="true">→</span>
                      </CustomLink>
                    )}
                  </div>
                ),
                ["emailAndPhone"]: (
                  <div className="mt-10 sm:mt-12">
                    <EmailSubmitField
                      id={`LandHero${settings?.emailSubmit[0]._uid}`}
                      blok={settings?.emailSubmit[0]}
                      hideDisclaimer
                      fromJourney={blok.signupFromJourney}
                    />
                  </div>
                ),
                ["authCTA"]: (
                  <div className="py-8 sm:py-7">
                    {blok.cta?.map((ctaBlok) => (
                      <AuthCTAButton key={ctaBlok._uid} blok={ctaBlok} />
                    ))}
                  </div>
                ),
              }[blok.ctaOptions]
            }
            {!!blok.used_by_label && (
              <p className="mt-8 text-base font-semibold text-white sm:mt-7">
                {blok.used_by_label}
              </p>
            )}
            {!!checkmarkList.length && (
              <div className="mt-2 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                <ul
                  role="list"
                  className="mt-1 grid grid-cols-2 gap-x-8 gap-y-3 text-base leading-7 text-white"
                >
                  {checkmarkList.map((checkmark) => (
                    <li key={checkmark} className="flex gap-x-3">
                      <CheckCircleIcon
                        className="h-7 w-5 flex-none"
                        aria-hidden="true"
                      />
                      {checkmark}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="aspect-h-4 aspect-w-5 relative md:-mb-2 lg:my-10 lg:pl-4">
            <NextImage
              className="rounded-lg object-cover shadow-lg"
              src={transformImage(
                blok.image.filename,
                "1500x0/filters:quality(80)"
              )}
              alt="landing page hero image"
              fill
              priority
              sizes="(max-width: 767px) 100vw, (max-width: 1023px) 80vw, (max-width: 2000px) 50vw, 30vw"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandHero;

import NextHead from "next/head";
import Script from "next/script";
import { useEffect } from "react";

const CUSTOM_HUBSPOT_FORM_READY_EVENT = "hbsptFormsLoaded";

const HubspotForm = ({
  blok,
}: {
  blok: {
    _uid: string;
    hubspotFormId: string;
  };
}) => {
  useEffect(() => {
    const loadForm = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "2463807",
          formId: blok.hubspotFormId,
        });
      }
    };

    // Check if the script has already loaded
    if (window.hbspt) {
      loadForm();
    } else {
      // Attach an event listener for when the script finishes loading
      window.addEventListener(CUSTOM_HUBSPOT_FORM_READY_EVENT, loadForm);
    }

    return () => {
      // Clean up event listener if component unmounts
      window.removeEventListener(CUSTOM_HUBSPOT_FORM_READY_EVENT, loadForm);
    };
  }, [blok.hubspotFormId]);

  return (
    <div key={`hubspot-form-${blok._uid}`}>
      <NextHead>
        <Script
          id="hubspot-forms"
          src="//js-eu1.hsforms.net/forms/embed/v2.js"
          async
          onLoad={() => {
            // Dispatch an event when the script is loaded
            window.dispatchEvent(new Event(CUSTOM_HUBSPOT_FORM_READY_EVENT));
          }}
        />
      </NextHead>
    </div>
  );
};

export default HubspotForm;

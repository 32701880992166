import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { triggerAnalyticsEvent } from "utils/analytics";

const FaqSection = ({ blok, className = undefined, style = undefined }) => {
  return (
    <div className={clsx("bg-white", className)} style={style}>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-20">
        <div className="mx-auto max-w-6xl divide-y divide-gray-900/10">
          {!!blok.title && (
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              {blok.title}
            </h2>
          )}
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {blok.faqs.map((faq) => (
              <Disclosure as="div" key={faq._uid} className="pt-6">
                {({ open }) => {
                  // Track what questions are most interesting for users.
                  if (open) {
                    triggerAnalyticsEvent("web_faq_clicked", {
                      question: faq.question,
                      answer: faq.answer,
                    });
                  }

                  return (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">
                            {faq.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <div className="text-base leading-7 text-gray-600">
                          <Markdown>{faq.answer}</Markdown>
                        </div>
                      </Disclosure.Panel>
                    </>
                  );
                }}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
